import { fetchWithToken } from "../services/api";
import { API_BASE_URL, ADMIN_API_BASE_URL } from "../config/api";
import { sanitizeURISlug } from "../helpers/apiHelpers";

export const fetchGameInfo = (): Promise<GameInfo[]> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${ADMIN_API_BASE_URL}/games`,
  });

export const fetchSetInfo = (
  gameCode: string,
  setId: number
): Promise<SetCard[]> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${ADMIN_API_BASE_URL}/game/${sanitizeURISlug(
      gameCode
    )}/set/${setId}?includeCounts=true&status=all`,
  });

type CardSearchOptions = {
  q?: string;
  gameCode?: string;
  cardName?: string;
  rarity?: string;
  number?: string;
  setName?: string;
  offset?: number;
  limit?: number;
  sort?: [string];
};

type CardSearchResult = {
  totalElements: number;
  totalPages: number;
  data: SearchCard[];
};

export const fetchCardSearch = (
  options: CardSearchOptions
): Promise<CardSearchResult> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/card/search`,
  });

export const updateCardInfo = (data: CardInfoVariables): Promise<Grading> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${API_BASE_URL}/grading/updateCardInfo`,
    payload: data,
  });
