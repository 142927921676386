export type OptionalParamsObject = {
  [key: string]: string | string[] | number | boolean | undefined;
};

export const buildQueryFromOptionalParams = (
  params: OptionalParamsObject
): string =>
  Object.entries(params)
    .map(([key, value]) => (value !== undefined ? `&${key}=${value}` : ""))
    .join("");

export const sanitizeURISlug = (slug: string | undefined) => {
  if (!slug) {
    return slug;
  }
  // First replace problematic characters
  const sanitized = slug
    .replace("#", "%23")
    .replace("/", "%2F")
    .replace("&", "%26");

  // Then do a full URI encode
  return encodeURIComponent(sanitized);
};

