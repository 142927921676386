import { useQueryClient, useQuery, useMutation } from "react-query";
import {
  createUser,
  fetchUsers,
  fetchMe,
  updateUser,
  updateUserEnable,
  updateUserDisable,
  updateResetPassword,
  fetchBuckets,
  fetchRoles,
  deleteUser,
} from "../api/user";

const LONG_LIFE_DATA_STALE_TIME = 5 * 60 * 1000; // ms

export const useFetchUsers = () => useQuery(["users"], fetchUsers);

export const useFetchMe = () => useQuery(["me"], fetchMe);

export const useFetchBuckets = () =>
  useQuery(["buckets"], fetchBuckets, { staleTime: LONG_LIFE_DATA_STALE_TIME });

export const useFetchRoles = () => useQuery(["roles"], fetchRoles);

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (user: User) => createUser(user),
    {
      onSuccess: (createdUser: User) => {
        queryClient.setQueryData<User[]>(["users"], (prev): User[] => {
          if (prev) {
            return [...prev, createdUser];
          }
          return [createdUser];
        });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (user: User) => updateUser(user),
    {
      onSuccess: (updatedUser: User, update: User) => {
        queryClient.setQueryData<User[]>(["users"], (prev): User[] => {
          if (prev) {
            return prev.map((user) =>
              user.id === update.id ? updatedUser : user
            );
          }
          return [updatedUser];
        });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useEnableUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (userId: number) => updateUserEnable(userId),
    {
      onSuccess: (updatedUser: User, userId: number) => {
        queryClient.setQueryData<User[]>(["users"], (prev): User[] => {
          if (prev) {
            return prev.map((user) =>
              user.id === userId ? updatedUser : user
            );
          }
          return [updatedUser];
        });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useDisableUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (userId: number) => updateUserDisable(userId),
    {
      onSuccess: (updatedUser: User, userId: number) => {
        queryClient.setQueryData<User[]>(["users"], (prev): User[] => {
          if (prev) {
            return prev.map((user) =>
              user.id === userId ? updatedUser : user
            );
          }
          return [updatedUser];
        });
      },
    }
  );
  return { isMutating, ...rest };
};

export const useResetPassword = () => {
  const { isLoading: isMutating, ...rest } = useMutation((userId: number) =>
    updateResetPassword(userId)
  );
  return { isMutating, ...rest };
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  const { isLoading: isMutating, ...rest } = useMutation(
    (userId: number) => deleteUser(userId),
    {
      onSuccess: (response: GenericResponse, userId: number) => {
        queryClient.setQueryData<User[]>(["users"], (prev): User[] => {
          if (prev) {
            return prev.filter((user) => user.id !== userId);
          }
          return [];
        });
      },
    }
  );
  return { isMutating, ...rest };
};
