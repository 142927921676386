import { fetchWithToken } from "../services/api";
import { API_BASE_URL } from "../config/api";

export const createUser = (user: User): Promise<User> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${API_BASE_URL}/user/add`,
    payload: user,
  });

export const fetchUsers = (): Promise<User[]> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/users`,
  });

export const fetchMe = (): Promise<User> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/me`,
  });

export const updateUser = (user: User): Promise<User> =>
  fetchWithToken({
    method: "PUT",
    endpoint: `${API_BASE_URL}/user/update`,
    payload: user,
  });

export const updateUserEnable = (id: number): Promise<User> =>
  fetchWithToken({
    method: "PUT",
    endpoint: `${API_BASE_URL}/user/${id}/enable`,
  });

export const updateUserDisable = (id: number): Promise<User> =>
  fetchWithToken({
    method: "PUT",
    endpoint: `${API_BASE_URL}/user/${id}/disable`,
  });

export const updateResetPassword = (id: number): Promise<GenericResponse> =>
  fetchWithToken({
    method: "POST",
    endpoint: `${API_BASE_URL}/user/${id}/resetPassword`,
  });

export const fetchBuckets = (): Promise<Bucket[]> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/buckets`,
  });

export const fetchRoles = (): Promise<Role[]> =>
  fetchWithToken({
    method: "GET",
    endpoint: `${API_BASE_URL}/roles`,
  });

export const deleteUser = (userId: number): Promise<GenericResponse> =>
  fetchWithToken({
    method: "DELETE",
    endpoint: `${API_BASE_URL}/user/${userId}`,
  });
