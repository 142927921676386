import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

import { filterArchived, filterReviewed } from "../helpers/tableHelpers";

import { useFetchSetInfo, useGetGameInfo } from "../hooks/cardHooks";

import FilterSearch from "../components/generic/FilterSearch";
import EditCardButton from "../components/catalog/EditCardButton";
import AddCardButton from "../components/catalog/AddCardButton";
import ExportCsvButton from "../components/catalog/exportCsvButton";

function CatalogSetView() {
  const params = useParams<{ gameCode: string; setId: string }>();
  const { gameCode = "", setId = "" } = params;

  const [search, setSearch] = useState("");
  const [showReviewed, setShowReviewed] = useState<"all" | "true" | "false">(
    "all"
  );
  const [showArchived, setShowArchived] = useState<"all" | "true" | "false">(
    "all"
  );
  const [setName, setSetName] = useState<string>("");

  const gameInfo = useGetGameInfo(gameCode);
  const { data } = useFetchSetInfo(gameCode, parseInt(setId, 10));

  useEffect(() => {
    setSearch("");
    if (gameInfo && gameInfo.sets) {
      const set = gameInfo.sets.find((s) => s.id === parseInt(setId, 10));
      if (set) {
        setSetName(set.setName);
      }
    }
  }, [gameCode, setId, gameInfo]);

  useEffect(() => {
    setSearch("");
  }, [gameCode, setName]);

  const normalizeString = (str: string | null | undefined) => {
    if (!str) return "";
    return str
      .normalize("NFD") // Normalize to NFD Unicode form
      .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
      .toLowerCase() // Convert to lowercase
      .trim(); // Remove whitespace
  };

  const filteredData =
    data
      ?.slice()
      .filter(filterArchived(showArchived))
      .filter(filterReviewed(showReviewed))
      .filter((item) => {
        if (!search) return true;
        const searchNormalized = normalizeString(search);

        return (
          normalizeString(item.title).includes(searchNormalized) ||
          normalizeString(item.cardName).includes(searchNormalized) ||
          normalizeString(item.number?.toString()).includes(searchNormalized) ||
          normalizeString(item.rarity).includes(searchNormalized)
        );
      }) || [];

  const InitData = {
    gameCode: gameCode,
    title: "",
    cardName: "",
    rarity: "",
    number: "",
    image: "",
    releaseDate: "",
    setName: setName, // Using the looked up setName
    cardsGraded: 0,
    finalGradeCounts: [],
    archived: false,
    reviewed: false,
  };

  return (
    <>
      <Typography variant="h2">{setName}</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "100%",
              flexWrap: "wrap",
              alignItems: "center",
              margin: "0 1rem",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <AddCardButton data={InitData} />
              <ExportCsvButton gameCode={gameCode} setName={setName} />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Box width={200} paddingRight={2}>
                <FormControl fullWidth>
                  <InputLabel id="filter-by-reviewed-label">
                    Filter by Reviewed
                  </InputLabel>
                  <Select
                    labelId="filter-by-reviewed-label"
                    id="filter-by-reviewed"
                    value={showReviewed}
                    label="Filter by Reviewed"
                    onChange={(e: any) => setShowReviewed(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box width={200}>
                <FormControl fullWidth>
                  <InputLabel id="filter-by-archived-label">
                    Filter by Archived
                  </InputLabel>
                  <Select
                    labelId="filter-by-archived-label"
                    id="filter-by-archived"
                    value={showArchived}
                    label="Filter by Archived"
                    onChange={(e: any) => setShowArchived(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="true">True</MenuItem>
                    <MenuItem value="false">False</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FilterSearch onChange={(value: string) => setSearch(value)} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 0 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={100}>Image</TableCell>
                  <TableCell width={150}>Card #</TableCell>
                  <TableCell>Card Name</TableCell>
                  <TableCell>Rarity</TableCell>
                  <TableCell width={100} style={{ textAlign: "right" }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.length > 0 ? (
                  filteredData?.map((row: any) => (
                    <TableRow key={row.id}>
                      <TableCell style={{ position: "relative" }}>
                        {row.image ? (
                          <div style={{ position: "relative" }}>
                            <img
                              src={row.image}
                              alt={row.title}
                              style={{
                                width: "40px",
                                height: "auto",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              onClick={() => window.open(row.image, "_blank")}
                              onMouseEnter={(e) => {
                                const preview = document.createElement("div");
                                preview.className = "image-preview";
                                preview.style.position = "fixed";
                                preview.style.backgroundColor = "white";
                                preview.style.padding = "5px";
                                preview.style.borderRadius = "4px";
                                preview.style.boxShadow =
                                  "0 2px 10px rgba(0,0,0,0.2)";
                                preview.style.zIndex = "1000";

                                const rect =
                                  e.currentTarget.getBoundingClientRect();
                                preview.style.left = `${rect.right + 10}px`;
                                preview.style.top = `${rect.top}px`;

                                const previewImg =
                                  document.createElement("img");
                                previewImg.src = row.image;
                                previewImg.style.maxWidth = "300px";
                                previewImg.style.maxHeight = "400px";
                                previewImg.style.width = "auto";
                                previewImg.style.height = "auto";
                                preview.appendChild(previewImg);

                                document.body.appendChild(preview);
                              }}
                              onMouseLeave={() => {
                                const preview =
                                  document.querySelector(".image-preview");
                                if (preview) {
                                  preview.remove();
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "40px",
                              height: "56px",
                              backgroundColor: "#f0f0f0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#999",
                              fontSize: "12px",
                              textAlign: "center",
                            }}
                          >
                            No Image
                          </div>
                        )}
                      </TableCell>
                      <TableCell>{row.number}</TableCell>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>
                        {row.rarity
                          ? row.rarity.charAt(0).toUpperCase() +
                            row.rarity.slice(1)
                          : ""}
                      </TableCell>
                      <TableCell>
                        <EditCardButton data={row} gameInfo={gameInfo} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} style={{ fontStyle: "italic" }}>
                      No results
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}

export default CatalogSetView;
