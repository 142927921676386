import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

type DeleteConfirmModalProps = {
  user: User;
  open: boolean;
  onClose: () => void;
  onConfirm: (userId: number) => void;
};

export const DeleteUserConfirmModal = ({
  user,
  open,
  onClose,
  onConfirm,
}: DeleteConfirmModalProps) => {
  const handleConfirm = () => {
    onConfirm(user.id!);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>Delete User</DialogTitle>
      <DialogContent>
        <Box>
          <p>Are you sure you want to delete the user {user.name}?</p>
          <p>This action cannot be undone.</p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="secondary" onClick={handleConfirm}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
