import { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { useDeleteUser } from "../../hooks/userHooks";
import { useAlert } from "../../hooks/alertHooks";
import ChangeEnabledStatus from "./ChangeEnabledStatus";
import ResetPassword from "./ResetPassword";
import { DeleteUserConfirmModal } from "./DeleteConfirmationModal";

const useStyles = makeStyles({
  link: {
    color: "black",
    textDecoration: "none",
  },
});

interface UserActionsMenuProps {
  user: User;
}

function UserActionsMenu(props: UserActionsMenuProps) {
  const { user } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { addAlert } = useAlert();
  const classes = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (userId: number) => {
    try {
      await deleteUser(userId);
      addAlert("User deleted successfully", "success");
    } catch (error) {
      addAlert("Failed to delete user", "error");
    }
  };

  if (!user?.id) return null;

  return (
    <>
      <Button
        aria-controls="user-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="user-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem>
          <ChangeEnabledStatus userId={user.id} enabled={user.enabled} />
        </MenuItem>
        <Link
          to={`/users/${user.id}`}
          onClick={() => handleClose()}
          className={classes.link}
        >
          <MenuItem>Edit details</MenuItem>
        </Link>
        <MenuItem>
          <ResetPassword userId={user.id} />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            setShowDeleteModal(true);
          }}
        >
          Delete user
        </MenuItem>
      </Menu>

      <DeleteUserConfirmModal
        user={user}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default UserActionsMenu;
